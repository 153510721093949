import { render, staticRenderFns } from "./LedgerAddressConfirmModal.vue?vue&type=template&id=49c314fd&scoped=true&"
import script from "./LedgerAddressConfirmModal.vue?vue&type=script&lang=js&"
export * from "./LedgerAddressConfirmModal.vue?vue&type=script&lang=js&"
import style0 from "./LedgerAddressConfirmModal.vue?vue&type=style&index=0&id=49c314fd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c314fd",
  null
  
)

export default component.exports