import { render, staticRenderFns } from "./LedgerModalNote.vue?vue&type=template&id=6a5d3767&scoped=true&"
import script from "./LedgerModalNote.vue?vue&type=script&lang=js&"
export * from "./LedgerModalNote.vue?vue&type=script&lang=js&"
import style0 from "./LedgerModalNote.vue?vue&type=style&index=0&id=6a5d3767&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5d3767",
  null
  
)

export default component.exports